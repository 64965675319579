import React from 'react'
import { Link, graphql } from 'gatsby'
import marked from 'marked'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import moment from 'moment'
// import BasePortableText from '@sanity/block-content-to-react'
// import { rhythm, scale } from '../utils/typography'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.post
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location}>
        <SEO
          title={`${post.metaTitle ||
            post.title} | J&J Automatic Gate Service & Design`}
          description={
            post.metaDescription ? post.metaDescription : post.excerpt
          }
        />

        <div
          id="blog-hero-section"
          className="blog-hero-section text-center"
          style={{
            background:
              (post.mainImage && `url(${post.mainImage.asset.url})`) || '',
            backgroundSize: `cover`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
          }}
        >
          <h1>
            {post.title}
            <span className="published-date d-block">
              {post.publishedAt
                ? moment(post.publishedAt).format('MMMM Do, YYYY')
                : ''}
            </span>
          </h1>
        </div>
        <div className="container blogpost-wrap mt-5">
          <div dangerouslySetInnerHTML={{ __html: marked(post.body) }}></div>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`/${previous.slug.current}`} rel="prev">
                  ← {previous.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`/${next.slug.current}`} rel="next">
                  {next.title} →
                </Link>
              )}
            </li>
          </ul>
          <Link to="/blog">Return</Link>
          <br />
          <br />
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      body
      author {
        id
        name
      }
      mainImage {
        asset {
          id
          url
          originalFilename
          fluid {
            src
          }
        }
      }
      publishedAt
      categories {
        _id
        title
      }
      slug {
        current
      }
      metaTitle
      metaKeywords
      metaDescription
    }
  }
`
